import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Nav from '../components/nav';

import { Main, Menu, Item } from '../styles';

const StyledAppBar = styled(AppBar)`
  background-color: #ad1d41;
  color: #fafafa;
`;

const StyledTabs = styled(Tabs)`
  .MuiTabs-indicator {
    background-color: #00d664;
  }
  .MuiTabs-scrollButtons {
    color: #00d664;
  }
  /* @media (min-width: 768px) {
    .MuiTabs-flexContainer {
      justify-content: center;
      Only use if the number of tab don't need to scroll 
    }
  } */
`;

const IndexPage = () => {
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function handleChangeIndex(index) {
    setValue(index);
  }

  return (
    <Layout>
      <SEO
        title="Esfihas - Novidade da Pizzaria Castelli"
        keywords={[
          `esfihas`,
          `esfihas doces`,
          `pizza`,
          `esfihas gourmet`,
          `esfihas especiais`,
          `borda paozinho`,
          `borda vulcao`,
          `sucos`,
          `bebidas`,
          `disk entregas`,
        ]}
      />
      <Main>
        <Nav />

        <h1>
          Esfihas<span>!</span>
          <span>!</span>
          <span>!</span>
        </h1>
        <h2>Difícil comer somente uma..</h2>

        <StyledAppBar position="sticky" color="default" square elevation={0}>
          <StyledTabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="inherit"
            variant="scrollable"
            scrollButtons="on"
          >
            <Tab label="Carne" />
            <Tab label="Especial" />
            <Tab label="Frango" />
            <Tab label="Calabresa" />
            <Tab label="Queijo" />
            <Tab label="Diversos" />
            <Tab label="Gourmet" />
            <Tab label="Doces" />
          </StyledTabs>
        </StyledAppBar>

        <SwipeableViews
          index={value}
          onChangeIndex={handleChangeIndex}
          animateHeight
        >
          <Menu id="menu">
            <h1 className="menu-title">Esfihas de Carne</h1>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">01 - Carne</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 4,50
                  </h4>
                </div>
              </div>

              <p className="item-desc">Carne moída com tomate e cebola.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">02 - Carne com Queijo</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 5,50
                  </h4>
                </div>
              </div>

              <p className="item-desc">Carne moída com queijo.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">03 - Carne com Cheddar</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 5,50
                  </h4>
                </div>
              </div>

              <p className="item-desc">Carne moída com cheddar.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">04 - Carne com Catupiry</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 5,50
                  </h4>
                </div>
              </div>

              <p className="item-desc">Carne moída com catupiry.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">05 - Carne com Cream Cheese</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 5,50
                  </h4>
                </div>
              </div>

              <p className="item-desc">Carne moída com cream cheese.</p>
            </Item>
          </Menu>

          <Menu id="menu">
            <h1 className="menu-title">Esfihas Especiais</h1>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">06 - Mexicana</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 7,50
                  </h4>
                </div>
              </div>

              <p className="item-desc">Mussarela, pepperoni e pimentão.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">07 - Pepperoni</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 6,50
                  </h4>
                </div>
              </div>

              <p className="item-desc">Mussarela e peperoni.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">08 - Palmito</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 6,50
                  </h4>
                </div>
              </div>

              <p className="item-desc">Palmito, mussarela e catupiry.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">09 - Brócolis</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 6,50
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Mussarela, brócolis, catupiry, bacon e alho.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">10 - Americana</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 6,50
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Presunto, ervilha, milho, palmito e bacon.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">11 - Bacon</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 6,50
                  </h4>
                </div>
              </div>

              <p className="item-desc">Mussarela e bacon.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">12 - Doritos</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 7,50
                  </h4>
                </div>
              </div>

              <p className="item-desc">Mussarela, cheddar e doritos.</p>
            </Item>
          </Menu>

          <Menu id="menu">
            <h1 className="menu-title">Esfihas de Frango</h1>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">13 - Frango</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 5,50
                  </h4>
                </div>
              </div>

              <p className="item-desc">Frango.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">14 - Frango com Queijo</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 5,50
                  </h4>
                </div>
              </div>

              <p className="item-desc">Frango e queijo.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">15 - Frango com Catupiry</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 5,50
                  </h4>
                </div>
              </div>

              <p className="item-desc">Frango com catupiry.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">16 - Frango com Cream Cheese</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 5,50
                  </h4>
                </div>
              </div>

              <p className="item-desc">Frango com cream cheese.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">17 - Frango com Cheddar</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 5,50
                  </h4>
                </div>
              </div>

              <p className="item-desc">Frango com cheddar.</p>
            </Item>
          </Menu>

          <Menu id="menu">
            <h1 className="menu-title">Esfihas de Calabresa</h1>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">18 - Calabresa</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 5,00
                  </h4>
                </div>
              </div>

              <p className="item-desc">Calabresa.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">19 - Calabresa com Queijo</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 5,50
                  </h4>
                </div>
              </div>

              <p className="item-desc">Calabresa com queijo.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">20 - Calacatu</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 5,50
                  </h4>
                </div>
              </div>

              <p className="item-desc">Calabresa com catupiry.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">21 - Baiana</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 5,50
                  </h4>
                </div>
              </div>

              <p className="item-desc">Calabresa com pimenta e mussarela.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">22 - Calabresa fatiada</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 5,50
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Calabresa fatiada com queijo e cebola.
              </p>
            </Item>
          </Menu>

          <Menu id="menu">
            <h1 className="menu-title">Esfihas de Queijo</h1>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">23 - Queijo</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 5,00
                  </h4>
                </div>
              </div>

              <p className="item-desc">Mussarela e ricota.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">24 - Quatro Queijos</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 6,50
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Mussarela, catupiry, parmesão e provolone.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">25 - Marguerita</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 6,00
                  </h4>
                </div>
              </div>

              <p className="item-desc">Mussarela, tomate e manjericão.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">26 - Napolitana</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 5,50
                  </h4>
                </div>
              </div>

              <p className="item-desc">Mussarela, parmesão e tomate.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">27 - Milho</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 5,50
                  </h4>
                </div>
              </div>

              <p className="item-desc">Mussarela, milho e catupiry.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">28 - Alho Frito</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 5,50
                  </h4>
                </div>
              </div>

              <p className="item-desc">Mussarela e alho frito.</p>
            </Item>
          </Menu>

          <Menu id="menu">
            <h1 className="menu-title">Esfihas Diversos Sabores</h1>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">29 - Hambúrguer</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 8,00
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Mussarela, hambúrguer, presunto, tomate, bacon, catchup e
                mostarda.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">30 - Atum 1</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 8,00
                  </h4>
                </div>
              </div>

              <p className="item-desc">Atum, mussarela e cebola.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">31 - Atum 2</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 8,00
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Atum, mussarela, cebola e tomate cereja.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">32 - Peito de Peru</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 8,00
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Mussarela, peito de peru, cream cheese, tomate e cereja.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">33 - Presunto</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 8,00
                  </h4>
                </div>
              </div>

              <p className="item-desc">Mussarela, presunto e requeijão.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">34 - Castelli</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 8,00
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Mussarela, champgnon, cheddar e palmito.
              </p>
            </Item>
          </Menu>

          <Menu id="menu">
            <h1 className="menu-title">Esfihas Gourmet</h1>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">35 - Mineira</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 8,00
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Mussarela, linguiça caipira, bacon e catupiry.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">36 - Tropeira Especial</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 8,00
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Carne seca, mussarela, cebola e catupiry.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">37 - Costela</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 8,00
                  </h4>
                </div>
              </div>

              <p className="item-desc">Costela, mussarela e tomate cereja.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">38 - Moda da Casa</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 8,00
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Lombinho, brócolis, palmito, tomate e cebola.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">39 - Strogonoff de Frango</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 8,00
                  </h4>
                </div>
              </div>

              <p className="item-desc">
                Strogonoff, champgnon, catupiry, mussarela e batata palha.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">40 - Tomate</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 8,00
                  </h4>
                </div>
              </div>

              <p className="item-desc">Mussarela, tomate seco e rúcula.</p>
            </Item>
          </Menu>

          <Menu id="menu">
            <h1 className="menu-title">Esfihas Doces</h1>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">41 - Chocolate</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 5,50
                  </h4>
                </div>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">42 - Chocolate Branco</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 5,50
                  </h4>
                </div>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">43 - Brigadeiro</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 5,50
                  </h4>
                </div>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">44 - Prestígio</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 5,50
                  </h4>
                </div>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">45 - Ouro Branco</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 8,00
                  </h4>
                </div>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">46 - Sonho de Valsa</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 6,50
                  </h4>
                </div>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">47 - Chocolate com Morango</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 6,50
                  </h4>
                </div>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">48 - Ferrero Rocher</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 6,50
                  </h4>
                </div>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">49 - Banana Nevada</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 8,00
                  </h4>
                </div>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">50 - Doce de Leite</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 6,50
                  </h4>
                </div>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">51 - Chocolate com Óreo/Bis</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 6,50
                  </h4>
                </div>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">52 - Mesclada</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 6,50
                  </h4>
                </div>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">53 - Nutella com Morango</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 6,50
                  </h4>
                </div>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">54 - Ovomaltine</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 8,50
                  </h4>
                </div>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">55 - Kit Kat</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 6,50
                  </h4>
                </div>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">56 - Ninho com Morango</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 6,50
                  </h4>
                </div>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">57 - Chocolate com Kinder</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 8,50
                  </h4>
                </div>
              </div>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">58 - Chocolate com Tortuguita</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">
                    <span>$</span> 8,50
                  </h4>
                </div>
              </div>
            </Item>
          </Menu>
        </SwipeableViews>
      </Main>
    </Layout>
  );
};

export default IndexPage;
